import svgPanZoom from 'svg-pan-zoom'
import hammerHandler from '../derby/zoom/hammer_handler'

import { resetTooltip } from '../shared/tooltip'

var panZoomInstance  = null
var areZoomControlsUsed = false

export function isGpePackagePage () {
  return ((document.querySelector('.seating-chart-image.zoomed') && areZoomControlsUsed) ? true : false)
}

function isRedesign () {
  return document.querySelector('.stripe-elements .redesign-wrapper')
}

function setTooltip() {
  let tooltip = document.getElementById('svg-tooltip')
  if (tooltip) {
    tooltip.style.display = 'block'
    resetTooltip(tooltip)
  }
}

function hideTooltip() {
  let tooltip = document.getElementById('svg-tooltip')
  if (tooltip) {
    tooltip.style.display = 'none'
  }
}


function setSvgZoom() {
  let svg_id = '#Layer_1'
  let svg_div = ''
  let svg_div_mobile = $('.ticket-header-image.mobile').is(':visible')
  let height = 0

  if(document.getElementById('svg-seating-chart')){ svg_id = '#svg-seating-chart' }

  if(svg_div_mobile){

    svg_div = '.ticket-header-image.mobile .seating-chart-image.zoomed ' + svg_id
  } else {
    svg_div = '.rightsidemap ' + svg_id + ', .leftsidemap ' + svg_id + ', .gpe-seating-chart-image ' + svg_id
    if(document.querySelector('.rightsidemap, .leftsidemap, .gpe-seating-chart-image')){
      height = document.querySelector('.rightsidemap, .leftsidemap, .gpe-seating-chart-image').offsetHeight
    }

  }

  const svg = document.querySelector(svg_div)
  if (!svg) return

  panZoomInstance = svgPanZoom(svg_div, {
    panEnabled: true,
    controlIconsEnabled: false,
    zoomEnabled: true,
    dblClickZoomEnabled: true,
    mouseWheelZoomEnabled: false,
    preventMouseEventsDefault: true,
    zoomScaleSensitivity: 0.2,
    minZoom: 0.5,
    maxZoom: 3,
    fit: true,
    contain: false,
    center: true,
    refreshRate: 'auto',
    eventsListenerElement: null,
    customEventsHandler: hammerHandler
  })

  if(svg_div_mobile) {
    if(document.querySelector('.ticket-header-image.mobile')){
      height = document.querySelector('.ticket-header-image.mobile').offsetHeight
    }
    $('.ticket-header-image.mobile').css('height', `${height}px`)
  } else {
    if (!isRedesign()) {
      $('.rightsidemap, .leftsidemap, .gpe-seating-chart-image').css('height', `${height}px`)
    }
  }

  if(screen.width >= 767){
    $('.packageaccess').css('height', `${height-180}px`)
    $('.divoclose').css('height', `${height-180}px`)
  }
  setTooltip()
}


$(document).on('turbo:load', function() {
  setSvgZoom()
})

$(document).on('click', '.zoomIn', function() {
  panZoomInstance.zoom(panZoomInstance.getZoom()+0.2)
  areZoomControlsUsed = true
  hideTooltip()
})

$(document).on('click', '.zoomOut', function() {
  panZoomInstance.zoom(panZoomInstance.getZoom()-0.2)
  areZoomControlsUsed = true
  hideTooltip()
})

$(document).on('mouseover', '.group-package', function() {
  if(panZoomInstance) {
    panZoomInstance.reset()
    setTooltip()
  }
})

$(document).on('wheel', '.rightsidemap #Layer_1, .leftsidemap #Layer_1', function() {
  var max_width = window.matchMedia('(max-width: 768px)')
  if(max_width.matches && panZoomInstance) {
    panZoomInstance.enableMouseWheelZoom()
    hideTooltip()
  }
  else if(panZoomInstance) {
    panZoomInstance.disableMouseWheelZoom()
  }
})

$(document).on('click', '.zoomReset', function() {
  panZoomInstance.reset()
  areZoomControlsUsed = true
  hideTooltip()
})
