<template>
  <div
    id="customer_order_list"
    class="customer_order_list wrapper"
  >
    <div v-if="totalCount > 0">
      <div class="table-header">
        <div class="wrapper">
          <div class="order-number">
            {{ $t('orders.order_number') }}
          </div>

          <div class="table-event">
            {{ $t('orders.event') }}
          </div>

          <div class="date">
            {{ $t('orders.order_date') }}
          </div>

          <div class="show">
            {{ $t('orders.show') }}
          </div>
        </div>
      </div>

      <div class="orders">
        <div
          v-for="order in orders"
          :key="order.id"
          class="order"
        >
          <div class="order-number">
            <strong class="key">
              {{ $t('orders.order_number') }}:
            </strong>

            <div class="value">
              {{ order.opportunity_id }}
            </div>
          </div>

          <div class="table-event">
            <strong class="key">
              {{ $t('orders.event') }}:
            </strong>

            <div class="value">
              {{ order.event_name }}
            </div>
          </div>

          <div class="date">
            <strong class="key">
              {{ $t('orders.order_date') }}:
            </strong>

            <div class="value">
              {{ formatDate(order.created_at) }}
            </div>
          </div>

          <div class="show">
            <a
              class="btn btn-primary"
              :href="orderLink(order)"
            >
              {{ $t('orders.view') }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <div v-if="totalCount == 0">
      <div class="message">
        {{ $t('orders.no_orders') }}
      </div>

      <div class="cta">
        <a
          class="btn btn-secondary"
          href="/packages"
        >
          {{ $t('orders.continue_shopping') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentPage: 1,
      totalCount: -1,
      totalPages: 1,
      orders: []
    }
  },

  created() {
    this.fetchOrders()
  },

  methods: {
    fetchOrders() {
      return fetch(`/oa/orders?page=${this.currentPage}`, {
        credentials: 'same-origin',
        headers: { 'Content-Type': 'application/json' },
        method: 'GET',
      }).then((response) => {
        if (response.status == 200) {
          return response.json().then((body) => {
            this.currentPage = body.current_page
            // this.totalCount = body.total_count
            this.totalPages = body.total_pages
            this.filterOrders(body.orders)
          })
        } else {
          this.totalCount = 0
        }
      }).catch(() => {
        this.totalCount = 0
      })
    },

    filterOrders(orders) {
      // TODO: Figure out how to handle H4H CUG orders
      this.orders = orders.filter(order => { return order.h4h_cug != true })
      this.totalCount = this.orders.length
    },

    formatDate(date) {
      return new Date(date).
        toLocaleDateString(window.locale, { year: 'numeric', month: 'long', day: 'numeric' })
    },

    orderLink(order) {
      if (order.opportunity_id) {
        return `/customer/experiences/${order.opportunity_id_full}`
      } else {
        return `/customer/orders/${order.id}`
      }
    },
  }
}
</script>
