import 'lodash.clonedeep'
import 'jquery-ui'
import 'bootstrap-sass'
import 'ekko-lightbox'
import 'bs5-lightbox'
import 'jquery-validation'
import 'jquery-validation/dist/additional-methods'
import 'jQuery.print'
import 'mailcheck'
import 'whatwg-fetch'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import '@hotwired/turbo-rails'
import './turbo-shim'
// import AddToCartForms from '../shared/add_to_cart_forms'
import './google_tag_manager'
import './forms'
import './form_validation'
import './customer'
import './links/translate'
// import './event_interests'
import './info_request'
import './layout'
import './news_articles'
import './orders'
import './package'
import './print'
import './registration'
import './category_seating_chart'
import './zopim'
import './categories'
import './countdown'
import './search'
import './carousel'
import './hotel_carousel_vue'
import './cookie_consent'
import './weather'
import './bynder'
import './customers'
import './links/smooth_scrolling'
import './comparison_table'
import './blogs'
import './breadcrumbs'
import '../shared/tooltip'
import '../shared/terms_and_conditions'
// import '../shared/confirmation'
import './google_ads'
import './svg_package_zoom'
import '../shared/category_filter'
import './persist_scroll'
import './validate_qe_info_request'
//Images
import '../images'
import './customer_service_form'
import './customer_support_form'

import VueLoader from '../default/vue-loader'
import SeatingChartSortBy from '../shared/seating_chart_sort_by'
import './reservations'
import './fade_in_effect'
import './confirmation'
import '../shared/masonry'
// import GooglePurchaseEvent from './google_purchase_event'
import { trackPurchaseEvent as gtmTrackPurchaseEvent } from './google_tag_manager.js'

// Load order and set session before and Vue Forms load order
// import CartApi from '../shared/cart/cart_api'
// CartApi.getOrder()

document.addEventListener('turbo:load', () => {
  // AddToCartForms.loadH4hForm()

  if (document.getElementById('sort-by-filter')) {
    VueLoader.loadVueComponent(
      SeatingChartSortBy,
      document.getElementById('sort-by-filter')
    )
  }
  if(document.getElementById('purchaseConfirmation')){
    gtmTrackPurchaseEvent()
  }
})
